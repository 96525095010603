
<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div
          slot="header"
          style="position:relative"
        >
          <div class="flex query">
            <el-input
              style="width: 320px;"
              clearable
              placeholder="请输入菜单名称"
              v-model="queryData.name"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button
              type="success"
              class="ml10"
              @click="getDataList(true)"
            >查询</el-button>

            <el-button
              type="primary"
              @click="addOrEditPopup('','',0)"
            >新增菜单</el-button>
          </div>
        </div>

        <el-table
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          :tree-props="{children: 'list'}"
          row-key="menuId"
          border
          stripe
        >
          <el-table-column
            type="index"
            :index="1"
            label="#"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="enName"
            label="英文名称"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="url"
            label="路由地址"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="icon"
            label="图标"
            min-width="80"
          >
            <template slot-scope="scope">
              <img :src="scope.row.icon" style="width:50px;">
            </template>
          </el-table-column>
          <el-table-column
            prop="perms"
            label="权限"
            min-width="150"
          ></el-table-column>

          <el-table-column
            prop="orderNum"
            label="排序"
            min-width="80"
          ></el-table-column>
          <el-table-column
            fixed="right"
            prop="operation"
            label="操作"
            width="295px;"
          >
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button
                  type="text"
                  @click="addOrEditPopup(scope.row.menuId)"
                >编辑</el-button>
                <span class="operationLine">|</span>
                <el-button
                  class="F56C6C"
                  type="text"
                  @click="del(scope.row.menuId,scope.row.list)"
                >删除</el-button>
                <span
                  class="operationLine"
                  v-if="scope.row.parentId==0"
                >|</span>
                <el-button
                  class="E6A23C"
                  type="text"
                  @click="addOrEditPopup('',scope.row.menuId,1)"
                  v-if="scope.row.parentId==0"
                >添加子菜单</el-button>
                <span
                  class="operationLine"
                  v-if="scope.row.url"
                >|</span>
                <el-button
                  class="E6A23C"
                  type="text"
                  @click="addOrEditPopup('',scope.row.menuId,2)"
                  v-if="scope.row.url"
                >添加按钮</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-edit
      ref="addOrEdit"
      @refreshDataList="getDataList()"
    ></add-or-edit>
  </div>
</template>

<script>
import AddOrEdit from "./menu-add-or-edit";
export default {
  components: {
    AddOrEdit,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        name: "",
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id, parentId, type) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id, parentId, type);
      });
    },
    /**
     * 删除数据
     */
    del(id, list) {
      if (list && list.length > 0) {
        this.$message({
          message: "该菜单下面有子菜单，无法删除",
          type: "warning",
        });
        return;
      }
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http.delete(`admin/sys-menu/baseDeleteById/${id}`).then((res) => {
              if (res.data.code == 200) {
                this.getDataList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .get(`admin/sys-menu/getSelectPage`, {
          params: this.queryData,
        })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
