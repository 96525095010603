<template>
  <el-dialog
    :title="dataForm.menuId?'修改':'新增'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-form
      ref="dataForm"
      :rules="dataRule"
      :model="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item
        label="类型"
        prop="type"
      >
        <el-radio-group v-model="dataForm.type">
          <el-radio
            v-for="(type, index) in typeList"
            :label="index"
            :key="index"
          >{{ type }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="typeList[dataForm.type] + '名称'"
        prop="name"
      >
        <el-input
          v-model="dataForm.name"
          placeholder="请输入菜单名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="typeList[dataForm.type] + '英文名称'"
        prop="enName"
      >
        <el-input
          v-model="dataForm.enName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.type === 1"
        label="菜单路由"
        prop="url"
      >
        <el-input
          v-model="dataForm.url"
          placeholder="菜单路由"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="dataForm.type !== 0"
        label="授权标识"
        prop="perms"
      >
        <el-input
          v-model="dataForm.perms"
          placeholder="多个用逗号分隔, 如: user:list,user:create"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="排序号"
        prop="orderNum"
      >
        <el-input-number
          v-model="dataForm.orderNum"
          controls-position="right"
          :min='1'
          label="排序"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        v-if="dataForm.type !== 2"
        label="图标"
        prop="icon"
      >
        <el-upload
          class="avatar-uploader width50height50"
          :action="$store.state.uploadImgUrl"
          :show-file-list="false"
          :on-success="uploadSuccess"
          :on-error="this.$uploadError"
          :before-upload="this.$beforeUploadImage"
          accept="image/*"
        >
          <img
            style="background-color:#999;display: block;width:100%;"
            v-if="dataForm.icon"
            :src="dataForm.icon"
          >
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
          ></i>
        </el-upload>
        <!-- <el-input
          style="margin-top:10px;"
          v-model="dataForm.icon"
          placeholder="请输入icon地址"
        ></el-input> -->
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="visible=false">取 消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import vuePy from "../../../utils/vue-py";
export default {
  data() {
    return {
      visible: false,
      typeList: ["目录", "菜单", "按钮"],
      dataForm: {
        type: 1,
        orderNum: 0,
        parentId: 0,
      },
      dataRule: {
        type: [{ required: true, message: "该项为必选项", trigger: "change" }],
        name: [{ required: true, message: "该项为必填项", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    /**
     * 获取文字首字母拼音
     */
    getPinYinCode(name) {
      let tempPinYin = vuePy.chineseToPinYin(name);
      let str = "";
      for (let i = 0; i < tempPinYin.length; i++) {
        let c = tempPinYin.charAt(i);
        if (/^[A-Z]+$/.test(c)) str += c;
      }
      this.$set(this.dataForm, "url", str);
    },
    init(id, parentId, type) {
      this.visible = true;
      if (!id)
        this.dataForm = {
          type: 1,
          orderNum: 0,
          parentId: 0,
        };
      if (id) {
        this.$http.get(`admin/sys-menu/baseGetById/${id}`).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data;
            if (data) this.dataForm = data;
          }
        });
      } else if (parentId) this.$set(this.dataForm, "parentId", parentId);
      if (type || type === 0) this.$set(this.dataForm, "type", type);
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let url = "admin/sys-menu/saveOrUpdate";
          let data = this.dataForm;
          this.$http.post(`${url}`, data).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
    /**
     * 上传图片成功
     */
    uploadSuccess(res) {
      this.$store.state.beforeUploadLoading.close();
      this.$set(this.dataForm, "icon", res.data);
    },
  },
};
</script>

<style lang='less' scoped>
</style>
